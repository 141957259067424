.App {
  /*text-align: center;*/
  background-color: #F8F4E3;
  min-height: 800px;
  height: 100%;
  margin: 0;
  padding-bottom: 10%;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
hr.dashed {
  border-top: 3px dashed #bbb;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.navBar {
  width: 100%;
  background-color: #0B4F6C;
  /*padding-right:60%;*/
}
.navLink {
  color: whitesmoke;
}
.navItem {
  color: whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.card {
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  min-height: 600px;
}
.cardImage {
  max-width: 500px;
  height: auto;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sidenote {
  font-style: italics;
  color: gray;
}