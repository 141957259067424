.resumeItemCard {
    max-width: 290px;
    min-height: 90%;
    margin: 30px;
}
.resumeItemCardButton {
    margin-left: 25%;
    margin-bottom: 20px;
}
.resumeItemCardTitle {
    margin: 20px;
}
.resumeItemCardImage {
    max-width: 200px;
    max-height: 200px;

    margin: auto;
}
.resumeItemCardTag {
    margin:3px;
}
.resumeItemDetails {
    overflow-y:scroll;
    max-height: 300px;
}